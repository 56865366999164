<template>
  <div class="default-card-box">

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10">
        <h3 class="font-size-h1">Forget Password</h3>
      </div>

      <!--begin::Form - Login -->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          v-if="errors" 
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
            placeholder="Enter Your Email"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            @click="gotoLogin()" 
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Sign In?
          </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Submit
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import Form from '@/core/services/api.form.services';

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: new Form({
        email: null
      }),
      api_url:'password/create'
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      //const email = this.$v.form.email.$model;
      this.loading=true;
      this.form.post(this.api_url).then((response) => {
        if(response.status){
          this.$showResponse('success', response.message);
          this.gotoLogin();
        }else{
          this.errors
        }
      })
    },
    gotoLogin(){
      this.$router.push({ path: '/login' })
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>

<style>
.login-text-css {
    background-color: #00163b;
    border-color: #00163b;
    color: #ffffff;
}
.default-card-box {
    display: block;
    margin: 0 0 30px;
    border-radius: 15px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 2px 2px rgb(205 206 226 / 55%);
    border: 1px solid #e0e0e0;
    width: 400px!important;
}
</style>
